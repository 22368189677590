<script setup>
    const { localeProperties } = useI18n();

    // Get WordPress URL to redirect when click on logo
    let wpUrl = 'https://stocklear.eu';

    if (localeProperties.value) {
        wpUrl = `${localeProperties.value.protocol}://${localeProperties.value.api.replace('api.', '')}`;
    }

    // Props definition
    const props = defineProps({
        white: {
            type: Boolean,
            default: false,
        },
        reduced: {
            type: Boolean,
            default: false,
        },
    });

    const error = ref(false);

    const imgSource = computed(() => {
        let url = `${localeProperties.value.protocol}://${localeProperties.value.api}`;

        if (props.reduced && error.value === false) {
            return `${url}/images/header/logo-sl.svg`;
        }

        if (props.white && error.value === false) {
            return `${url}/images/header/logo-white.svg`;
        }

        return `${url}/images/header/logo.svg`;
    })
</script>

<template>
    <NuxtLink id="app__logo" :to="wpUrl">
        <NuxtImg
            :src="imgSource"
            alt="Logo Stocklear"
            height="2rem"
            @error="error = true"
        />
    </NuxtLink>
</template>

<style lang="scss" scoped>
    #app__logo {
        display: flex;
        max-width: 100%;

        img {
            max-height: 2rem;
            max-width: 100%;
        }
    }
</style>